export const ModoExibicaoDocumentos = [
  { value: 0, label: "Vaga" },
  { value: 1, label: "Cpf" },
  { value: 1, label: "Site" }
];


export const StatusDocuments = [
  { id: 2, name: "Reenviar" },
  { id: 3, name: "Aprovado" },
  { id: 4, name: "Descartado" }
];

export const TipoContrato = [

  { id: 0, name: "Aprendiz Adm" },
  { id: 1, name: "Aprendiz Teleservico" },
  { id: 2, name: "Estagiãrio" },
  { id: 3, name: "Atendentes" },
  { id: 4, name: "Administrativo" },
  { id: 5, name: "Administrativo - Gestores" },
  { id: 6, name: "Administrativo - Isento de ponto" },
  { id: 7, name: "Sem fronteira" }

];


export const bankOptions = [
  { value: 0, label: "237 - Bradesco" },
  { value: 1, label: "033 - Santanter" }
];

export const alternativeBankOptions = [
  { value: 0, label: "BancoDoBrasilSA_001" },
  { value: 1, label: "BancoSantanderSA_033" },
  { value: 2, label: "CaixaEconomicaFederal_104" },
  { value: 3, label: "BancoBradescoSA_237" },
  { value: 4, label: "BancoItauSA_341" },
  { value: 5, label: "BancoMercantilDoBrasilSA_389" },
  { value: 6, label: "HSBCBankBrasilSABancoMUltiplo_399" },
  { value: 7, label: "BancoSafraSA_422" },
  { value: 8, label: "BancoRuralSA_453" },
  { value: 9, label: "BancoRendimentoSA_633" },
  { value: 10, label: "ItauUnibancoHoldingSA_652" },
  { value: 11, label: "BancoCitibankSA_745" }
];

export const countAlternativeOptions = [
  { value: 0, label: "Conta corrente" },
  { value: 1, label: "Conta poupança" }
];

export const countOptions = [
  { value: 0, label: "Corrente" },
  { value: 1, label: "Salário" },
  { value: 2, label: "Poupança" }
];

export const StatusEtapaContratos = [
  { value: 10, label: "Aguardando Admissão" },
  { value: 23, label: "Pendente de Liberação" },
  { value: 0, label: "Aguardando Assinatura" },
  { value: 3, label: "Aprovado" },
  { value: 4, label: "Dispensado" },
  { value: 19, label: "Aguardando Aceite Alteração do Contrato" },
  { value: 20, label: "Divergência" },
  { value: 21, label: "Aguardando Aceite Cancelamento" },
  { value: 4, label: "Dispensado" },
  { value: 17, label: "Divergencia Detectada Pelo Colaborador" },
  { value: 24, label: "Pendente Assinatura Administrativo" },
];

export const statusAssinaturaAdministrador = [
  { value: 3, label: "Aprovado" },
  { value: 20, label: "Divergência" },
];

export const StatusEtapaDocumentos = [
  { value: 0, label: "Não enviado" },
  { value: 1, label: "Em análise" },
  { value: 3, label: "Aprovado" },
  { value: 4, label: "Dispensado" },
  { value: 18, label: "Desistência" }
];

export const TipoVagaSelecao = [
  { value: 0, label: "Interna" },
  { value: 1, label: "Externa" }
];
export const TypesIndicator = [
  { id: 0, nome: "Por mês" },
  { id: 1, nome: "Por dia" }
];

export const YesAndNo = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

export const typeadmission = [
  { value: "P", label: "Sim" },
  { value: "R", label: "Não" }
];

export const spouseInformation = [
  { value: 0, label: "Cônjuge" },
  { value: 1, label: "Companheiro" },
  { value: 2, label: "Não possuo" }
];

export const typesContracts = [
  { value: "0", label: "Contrato prazo determinado" },
  { value: "1", label: "Contrato experiência" }
];

export const situations = [
  { value: 0, label: "A" },
  { value: 1, label: "Z" }
];
export const optionsSEFIP = [
  { value: 0, label: "Nunca exposto a agentes nocivos" },
  { value: 1, label: "Não exposição a agente nocivo - 1 vinculo(já esteve exposto)" },
  { value: 2, label: "Exp. a ag. nocivo (apos. aos 15 anos de serviço) 1 vinculo" },
  { value: 3, label: "Exp. a ag. nocivo (apos. aos 20 anos de serviço) 1 vinculo" },
  { value: 4, label: "Exp. a ag. nocivo (apos. aos 25 anos de serviço) 1 vinculo" },
  { value: 5, label: "Não exposição a agente nocivo + de 1 vinculo" },
  { value: 6, label: "Exp. a ag. nocivo(apos. aos 15 anos de serviço) + de 1 vinc." },
  { value: 7, label: "Exp. a ag. nocivo(apos. aos 20 anos de serviço) + de 1 vinc." },
  { value: 8, label: "Exp. a ag. nocivo(apos. aos 25 anos de serviço) + de 1 vinc." },
];

export const optionsContractPartialRegime = [
  { value: 0, label: "Não é contrato em tempo parcial" },
  { value: 1, label: "Limitado a 25 horas semanais" },
  { value: 2, label: "Limitado a 30 horas semanais" },
  { value: 3, label: "Limitado a 26 horas semanais" },
];

export const optionsTypeRegimeJorney = [
  { value: 0, label: "Submetidos a Horário de Trabalho (Cap, II da CLT)" },
  { value: 1, label: "Atividade Externa especificada no Inciso I do Art, 62 da CLT" },
  { value: 2, label: "Funções especificadas no Inciso II do Art. 62 da CLT" },
  { value: 3, label: "Teletrabalho, previsto no Inciso III do Art. 62 do CLT" },
];
export const fixCodeValue = [
  { value: "1", label: "Sim" },
  { value: "0", label: "Não" }
];

export const pointRecordValue = [
  { value: "01", label: "Sim" },
  { value: "02", label: "Não" }
];

export const registrationIntegrationValue = [
  { value: "01", label: "Sim" },
  { value: "02", label: "Não" }
];

export const registrationHierarchyValue = [
  { value: "1", label: "Sim" },
  { value: "2", label: "Não" }
];

export const determinedDeadlines = [
  { value: "0", label: "Definido em dias" },
  { value: "1", label: "Vinculado á ocorrência de um fato" }
];

export const OptionsCodeEvent = [
  { value: "0117", label: "0117 - ADICIONAL DE PERICULOSIDADE" },
];

export const personGender = [
  { value: 0, label: "Masculino" },
  { value: 1, label: "Feminino" }
];

export const typesEthnicity = [
  { value: 0, label: "Indígena" },
  { value: 2, label: "Branca" },
  { value: 4, label: "Preta/Negra" },
  { value: 6, label: "Amarela" },
  { value: 8, label: "Parda" },
  { value: 9, label: "Não informado" },
  { value: 10, label: "Não declarado" },
];

export const typeDisabilityOptions = [
  { value: 1, label: "Físico" },
  { value: 2, label: "Auditivo" },
  { value: 3, label: "Fala" },
  { value: 4, label: "Visual" },
  { value: 5, label: "Mental" },
  { value: 6, label: "Reabilitado(BR)" },
  { value: 7, label: "Deficiente intelectual" }
];

export const typesMaritalStatus = [
  { value: 0, label: "Solteiro" },
  { value: 1, label: "Casado" },
  { value: 2, label: "Separado" },
  { value: 3, label: "Divorciado" },
  { value: 4, label: "Viúvo" },
  { value: 6, label: "Desquitado" },
  { value: 7, label: "União Estável" },
  { value: 5, label: "Outros" },
];

export const StatusDuvidaContato = [
  { value: 0, label: "Aguardado" },
  { value: 1, label: "Não enviado" },
  { value: 2, label: "Respondido" },
  { value: 3, label: "visualizado" },
];

export const StatusMotivoDoContato = [
  { value: 0, label: "Erro Sistemico" },
  { value: 1, label: "Duvida Processo Seletivo" },
  { value: 2, label: "Reclamação Processo seletivo" },
  { value: 3, label: "Outros" },
];

export const TipoDeDocumento = [
  { value: 4, label: "Contracheque" },
  { value: 5, label: "Recibo de Férias" },
  { value: 6, label: "Aviso de férias" },
  { value: 7, label: "Termo EPI" },
  { value: 9, label: "Aviso de Dispensa - Liberado do Aviso" },
  { value: 10, label: "Aviso de Dispensa - Cumprimento do Aviso" },
  { value: 11, label: "Termo de dispensa por Justa Causa" },
  { value: 12, label: "Término de contrato no vencimento" },
  { value: 13, label: "Término de contrato antecipado " }
];

export const TipoPagamento = [
  { value: 1, label: "Comissão" },
  { value: 2, label: "Variável" },
];


export const TiposPrimitivoDeSolicitacao = [
  { value: 1, label: "Vale Transporte" },
  { value: 2, label: "Vale Refeicao" },
  { value: 3, label: "Plano Saude" },
  { value: 4, label: "Contra Cheque" },
  { value: 5, label: "Ferias" },
  { value: 6, label: "Demissao" },
  { value: 8, label: "Ponto" },
  { value: 9, label: "Admissão"},
  { value: 7, label: "Outros" } //coloquei esse por ultimo só por questão organizacional da interface
];

export const FluxoValeTransporte = [
  { value: 1, label: "Segunda Via Roubo" },
  { value: 2, label: "Segunda Via Perda" },
  { value: 3, label: "Mudança De Endereço" },
  { value: 4, label: "Deixar de ser optante" },
  { value: 5, label: "Passar a ser optante" },
  { value: 6, label: "Outros" },
];

export const FluxoValeRefeicao = [
  { value: 1, label: "Segunda Via Roubo" },
  { value: 2, label: "Segunda Via Perda" },
  { value: 3, label: "Outros" },
];


export const FluxoPlanoDeSaude = [
  { value: 1, label: "Inclusão De Plano" },
  { value: 2, label: "Cancelamento de Plano" },
  { value: 3, label: "Outros" },
];

export const FluxoContraCheque = [
  { value: 1, label: "Desconto Indevido" },
  { value: 2, label: "Duvidas" },
  { value: 3, label: "Outros" },
];

export const FluxoFerias = [
  { value: 1, label: "Agendamento" },
  { value: 2, label: "Cancelamento" },
  { value: 3, label: "Outros" },
];

export const FluxoDemissao = [
  { value: 1, label: "Empresa" },
  { value: 2, label: "Empregado" },
];


export const FluxoPonto = [
  { value: 1, label: "Erro no ponto" },
  { value: 2, label: "Ajuste" },
  { value: 3, label: "Outros" },
]

export const FluxoAdmissao = [
  { value: 1, label: "Nova Admissão" },
];


export const StatusSolicitacaoCandidato = [
  { value: 0, label: "Aguardando" },
  { value: 1, label: "Assumido" },
  { value: 2, label: "Encerrado" },
  { value: 3, label: "Alerta" },
];

export const StatusSla = [
  { value: 0, label: "Dentro do Prazo" },
  { value: 1, label: "Dois Dias Fora" },
];

export const TiposDeUser = [
  { value: true, label: "Pessoa Física" },
  { value: false, label: "Pessoa Juridica" },
];