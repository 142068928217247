import styled from "styled-components";
import theme from "./Theme";

const convertPXtoEM = ({ baseEM, size }) => {
  const base = baseEM ? baseEM : 16;
  const em = size / base;
  return `${em}em`;
};

export const LabelStyle = styled.h1`
  font-family: Arial;
  font-size: ${(props) => (!props.fz ? "28px" : props.fz)};
  width: auto;
  font-style: normal;
  font-stretch: normal;
  font-weight: ${(props) => props.weight};
  line-height: normal;
  letter-spacing: normal;
  margin-left: "10px";
  margin-left: ${(props) => props.ml};
  color: ${(props) => theme.colors.titleColor};
  :hover {
    cursor: ${(props) => (props.action ? "pointer" : "cursor")};
  }
`;
export const TableColumnLogin = styled.td`
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  color: #4a4a4a;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: ${(props) => props.first && "18px"};
  width: 100%;
`;
export const ColumnTooltip = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
`;

export const SpanDefault = styled.span`
  font-size: ${(props) => (props.fs ? props.fs : "14px")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: ${(props) => (props.lh ? props.lh : "normal")};

  margin-left: ${(props) => props.ml};
  color: ${(props) => theme.defineColor(props.color)};
`;

export const BodyStyle = styled.div`
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const TextStyle = styled(SpanDefault)`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => theme.defineColor(props.color)};
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableRow = styled.tr`
  border-bottom: ${(props) =>
    props.noBorderBottom ? "none" : "solid 1px #F4F4F4"};
  border-top: ${(props) => (props.noBorderTop ? "none" : "solid 1px #F4F4F4")};

  &:hover td {
    background-color: ${(props) =>
      props.noHover ? "inherit" : "rgba(23, 179, 210, 0.05) !important"};
  }

  td {
    background-color: ${(props) =>
      props.AlertaGeral ? "#f6f6f6 !important" : "transparent"};
  }
`;

export const TableBody = styled.tbody``;

export const TableHead = styled.th`
  padding:17px;
  padding-left: ${(props) => (props.first ? "30px" : "0px")}
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  padding-left: ${(props) => (props.first ? "30px" : "0px")}
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-family: Arial;
  font-size: 13px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.defaultColor};
  svg {
    stroke: ${theme.colors.titleColor};
    fill: none;
    &:hover {
      color: ${theme.colors.defaultColor};
      cursor: pointer;
    }
  }
`;

export const TableColumn = styled.td`
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: 13px;
  color: ${theme.colors.defaultColor};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: ${(props) => props.first && "18px"};
  font-family: Arial;
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: ${(props) => props.first && "18px"};
`;

export const LabelAction = styled.span`
  opacity: 0.7;
  font-size: ${(props) => (props.fz ? props.fz : "10px")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  color: #4a4a4a;
  margin-top: ${(props) => (props.mt ? props.mt : "3px")};
  margin-left: ${(props) => (props.ml ? props.ml : "3px")};
  margin-right: ${(props) => (props.mr ? props.mr : "3px")};
  ${(props) => (props.style ? props.style : "")};
`;

export const Label = styled(LabelAction)`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  color: ${(props) =>
    props.color ? theme.defineColor(props.color) : "#4a4a4a"};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-weight: ${(props) => props.bold && "bold"};
`;

export const SimpleLabel = styled.label`
  font-size: ${(props) => convertPXtoEM(props)};
  color: ${theme.colors.softGray};
`;

export const LabelHeader = styled(Label)`
  color: ${theme.colors.defaultColor};
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: lighter;
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  &:hover {
    cursor: pointer;
  }
`;

export const Actions = styled.div`
  display: flex;
  width: auto;
  margin-left: ${(props) => props.ml};
  justify-content: ${(props) =>
    props.flexPosition ? props.flexPosition : "space-around"};
  align-items: baseline;
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  fill: ${theme.colors.iconColor};
  g {
    fill: ${theme.colors.iconColor};
  }
  text-transform: lowercase;
  &:hover {
    cursor: pointer;
    g {
      fill: ${theme.colors.defaultColor};
    }
    label {
      color: ${theme.colors.defaultColor};
    }
    span {
      color: ${theme.colors.defaultColor};
    }
    fill: ${theme.colors.defaultColor};
  }
`;

export const BodyDialog = styled.div`
  padding-bottom: 20px;
`;

export const RowActionsButton = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const RowQuestion = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SpanQuestion = styled.span`
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
`;

export const SpanQuestionItem = styled.div`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.colorLabelDefault};
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const HeaderDialog = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid ${theme.colors.ligthGray};
  padding-left: 20px;
  padding-right: 20px;
`;

export const FilterItemRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-items: ${(props) =>
    props.flexPosition ? props.flexPosition : "flex-start"};
  margin-bottom: ${(props) => props.mb};
  fill: ${(props) =>
    props.showFilterOptions ? theme.colors.defaultColor : " #9d9d9d"};
  :hover {
    cursor: pointer;
  }
  transition: color 0.5;
  flex-flow row wrap;
  width: 100%;
`;
export const ButtonContainer = styled.div`
  margin: 0em 1em 0.5em 0em;
  align-self: flex-end;
`;

export const FilterRegisterLabel = styled.span`
  height: 20px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(props) =>
    props.showFilterOptions ? theme.colors.defaultColor : " #9d9d9d"};
  margin-left: 3px;
  margin-right: 3px;
  transition: color 0.5;
`;

export const FilterBody = styled.div`
  width: 860px;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  z-index: 20;
  position: absolute;
  color: ${(props) =>
    props.showFilterOptions ? theme.colors.defaultColor : " #9d9d9d"};
  padding: 30px;
`;

export const SelectRow = styled.div`
  display: flex;
  width: 100%;
  margin: 1em 0em;
  flex-flow: row wrap;
`;

export const SelectsContainer = styled.div`
  margin: 0em 1em 0em 0em;
`;

export const ItemFilter = styled.div`
  height: auto;
  border-radius: 15px;
  background-color: ${theme.colors.titleColor};
  margin-left: 18px;
  padding: 6px;
  align-items: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;
export const RowActionsFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SpanFilterValue = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-right: 5px;
`;

export const H1 = styled.h1`
  font-family: Arial;
  font-size: ${(props) => (props.size ? props.size : "2.5rem")};
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${(props) =>
    props.color === "title"
      ? theme.colors.titleColor
      : theme.colors.defaultColor};
  :hover {
    color: ${theme.colors.defaultColorHover};
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    font-size: 1em !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const BodyCollapse = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  svg {
    transform: ${(props) =>
      props.openCollapse ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 0.4s;
  }
  ul,
  li {
    width: 100% !important;
  }
`;

export const HeaderCollapse = styled.div`
  height: 55px;
  border-bottom: solid 1px #ececec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    padding: 10px !important;
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;
export const ContentCollapse = styled.div`
  min-height: ${(props) => (props.openCollapse ? "240px" : "0px")};
  transition: all 0.4s;
  opacity: ${(props) => (props.openCollapse ? "1" : "0")};
  padding: ${(props) => props.openCollapse};
`;
export const ArrowCollapse = styled.img`
  width: 20px;
  height: 20px;
`;
export const TitleCollapse = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 100%;
`;

export const CollapseDot = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${(props) => {
    return theme.colors.titleColor.toString();
  }};
  font-size: 1em;
  text-align: center;
  justify-items: center;
  color: #ffffff;
  margin-right: 15px;
  @media screen and (max-width: ${theme.size.mobileL}) {
    font-size: 0.8em;
    margin-right: 10px;
    border: ${(props) => `1px solid theme.colors.titleColor.toString()`};
  }
`;
export const DataInfo = styled.span`
  font-family: Arial;
  font-size: 1em;
  color: ${theme.colors.darkGray};
  margin-left: ${(props) => props.ml};
  margin-bottom: ${(props) => props.mb};
`;

export const customStylesSelect = {
  container: (provided, state) => ({
    width: "100%",
    font: "arial",
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    borderColor: "#17B3D2",
    ...provided,
  }),
  control: (provided, state) => ({
    minHeight: "40px",
    placeholder: {
      fontSize: "14px",
      color: theme.colors.softGray,
    },
    ...provided,
    border: "1px solid #e0e0e0",
    "&:hover": {
      borderColor: theme.colors.defaultColor,
    },
  }),
  dropdownIndicator: (provided, state) => ({
    fill: theme.colors.defaultColor,
    svg: {
      fill: theme.colors.defaultColor,
      height: "15px",
    },
    ...provided,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  onFocus: (provided, state) => ({
    ...provided,
  }),
};

export const LabelInput = styled.label`
  font-size: ${(props) => (props.size ? props.size : "0.875em")};
  color: ${(props) => (props.color ? props.color : theme.colors.softGray)};
  margin-bottom: ${(props) => props.noMarginBottom && "0px"};
  display: flex;
  flex-grow: 100;
  margin-left: ${(props) => props.ml};
`;

export const InputRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.gridSize || '46px 1fr'};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px 0px;
`;
