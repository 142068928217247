import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  doubtsListRequest: ["payload"],
  doubtsListSuccess: ["stages", "isLoading"],
  doubtsListFail: ["isLoading", "error"],

  doubtsAnswersRequest: ["payload"],
  doubtsAnswersSuccess: ["stages", "isLoading"],
  doubtsAnswersFail: ["isLoading", "error"],

  doubtsUserRequest: ["payload"],
  doubtsUserSuccess: ["stages", "isLoading"],
  doubtsUserFail: ["isLoading", "error"],

  doubtsAnswersManagerRequest: ["payload"],
  doubtsAnswersManagerSuccess: ["stages", "isLoading"],
  doubtsAnswersManagerFail: ["isLoading", "error"],

  doubtsCreateManager: ["payload"],
  doubtsCreateManagerSuccess: ["stages", "isLoading"],
  doubtsCreateManagerFail: ["isLoading", "error"],

  alertCreateManager: ["payload"],
  alertCreateManagerSuccess: ["stages", "isLoading"],
  alertCreateManagerFail: ["isLoading", "error"],

  doubtsSendDocumentRequest: ["payload"],
  doubtsSendDocumentSuccess: ["stages", "isLoading"],
  doubtsSendDocumentFail: ["isLoading", "error"],

  downloadDocumentsSolicitationFilesRequest: ["payload"],
  downloadDocumentsSolicitationFilesSuccess: ["stages", "isLoading"],
  downloadDocumentsSolicitationFilesFail: ["isLoading", "error"],

  doubtsSolicitationRequest: ["payload"],
  doubtsSolicitationRequestSucess: ["stages", "isLoading"],
  doubtsSolicitationRequestFail: ["isLoading", "error"],

  getAllGeneralAlertsRequest: [],
  getAllGeneralAlertsSucess: ["stages", "isLoading"],
  getAllGeneralAlertsFail: ["isLoading", "error"],

  MarkAsReadRequest: ["payload"],
  MarkAsReadSucess: ["stages", "isLoading"],
  MarkAsReadFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  duvidas: [],
  hasMore: true,
  page: 1,
  users: []
};


const doubtsListRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});
const doubtsListSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const doubtsListFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});


const doubtsAnswersRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
});
const doubtsAnswersSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const doubtsAnswersFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const doubtsUserRequest = (state, payload) => ({
  ...state,
  ...payload,
});
const doubtsUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
});
const doubtsUserFail = (state, error) => ({
  ...state,
  ...error
});


const doubtsAnswersManagerRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});
const doubtsAnswersManagerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const doubtsAnswersManagerFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
});


const doubtsCreateManager = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
});
const doubtsCreateManagerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const doubtsCreateManagerFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const alertCreateManager = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
});
const alertCreateManagerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const alertCreateManagerFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});


const doubtsSendDocumentRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});
const doubtsSendDocumentSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const doubtsSendDocumentFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const downloadDocumentsSolicitationFilesRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});
const downloadDocumentsSolicitationFilesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const downloadDocumentsSolicitationFilesFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const doubtsSolicitationRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
})

const doubtsSolicitationRequestSucess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
})

const doubtsSolicitationRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const getAllGeneralAlertsRequest = (state) => ({
  ...state,
  isLoading: true,
});

const getAllGeneralAlertsSucess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getAllGeneralAlertsFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const MarkAsReadRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});

const MarkAsReadSucess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const MarkAsReadFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.DOUBTS_LIST_REQUEST]: doubtsListRequest,
  [Types.DOUBTS_LIST_SUCCESS]: doubtsListSuccess,
  [Types.DOUBTS_LIST_FAIL]: doubtsListFail,

  [Types.DOUBTS_ANSWERS_REQUEST]: doubtsAnswersRequest,
  [Types.DOUBTS_ANSWERS_SUCCESS]: doubtsAnswersSuccess,
  [Types.DOUBTS_ANSWERS_FAIL]: doubtsAnswersFail,

  [Types.DOUBTS_USER_REQUEST]: doubtsUserRequest,
  [Types.DOUBTS_USER_SUCCESS]: doubtsUserSuccess,
  [Types.DOUBTS_USER_FAIL]: doubtsUserFail,

  [Types.DOUBTS_ANSWERS_MANAGER_REQUEST]: doubtsAnswersManagerRequest,
  [Types.DOUBTS_ANSWERS_MANAGER_SUCCESS]: doubtsAnswersManagerSuccess,
  [Types.DOUBTS_ANSWERS_MANAGER_FAIL]: doubtsAnswersManagerFail,

  [Types.DOUBTS_CREATE_MANAGER]: doubtsCreateManager,
  [Types.DOUBTS_CREATE_MANAGER_SUCCESS]: doubtsCreateManagerSuccess,
  [Types.DOUBTS_CREATE_MANAGER_FAIL]: doubtsCreateManagerFail,

  [Types.ALERT_CREATE_MANAGER]: alertCreateManager,
  [Types.ALERT_CREATE_MANAGER_SUCCESS]: alertCreateManagerSuccess,
  [Types.ALERT_CREATE_MANAGER_FAIL]: alertCreateManagerFail,

  [Types.DOUBTS_SEND_DOCUMENT_REQUEST]: doubtsSendDocumentRequest,
  [Types.DOUBTS_SEND_DOCUMENT_SUCCESS]: doubtsSendDocumentSuccess,
  [Types.DOUBTS_SEND_DOCUMENT_FAIL]: doubtsSendDocumentFail,

  [Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_REQUEST]: downloadDocumentsSolicitationFilesRequest,
  [Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_SUCCESS]: downloadDocumentsSolicitationFilesSuccess,
  [Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_FAIL]: downloadDocumentsSolicitationFilesFail,

  [Types.DOUBTS_SOLICITATION_REQUEST]: doubtsSolicitationRequest,
  [Types.DOUBTS_SOLICITATION_REQUEST_SUCESS]: doubtsSolicitationRequestSucess,
  [Types.DOUBTS_SOLICITATION_REQUEST_FAIL]: doubtsSolicitationRequestFail,

  [Types.GET_ALL_GENERAL_ALERTS_REQUEST]: getAllGeneralAlertsRequest,
  [Types.GET_ALL_GENERAL_ALERTS_SUCESS]: getAllGeneralAlertsSucess,
  [Types.GET_ALL_GENERAL_ALERTS_FAIL]: getAllGeneralAlertsFail,

  [Types.MARK_AS_READ_REQUEST]: MarkAsReadRequest,
  [Types.MARK_AS_READ_SUCESS]: MarkAsReadSucess,
  [Types.MARK_AS_READ_FAIL]: MarkAsReadFail,
});
