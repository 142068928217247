import { Flag, H1 } from "./EditCandidateStyle";
import { ButtonDefault, TextField, DatePicker, SelectInput, RadioInput, } from "components/shared/CustomInputs";
import { FormattedMessage } from "react-intl";
import React, { useCallback, useState, useEffect } from 'react';
import { push } from "connected-react-router";
import { Row, Col, Modal, Alert, Button } from "react-bootstrap";
import { LabelH1 } from "../../../../../components/shared/CustomComponents";
import { YesAndNo } from "../../../../../utils/contants/enums";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { Title, FormattedMessageWithStyle } from "components/shared/CustomComponents";
import theme from "../../../../../components/shared/Theme";
import moment from "moment";
import BreadCrumb from "components/Layout/breadcrumb";
import { Creators as candidateActions } from "../../../../../store/ducks/candidates";
import { Body, LabelSpan, RequiredSpan, FixedAction } from "./EditCandidateStyle";
import FormGroup from "../../../../../components/Forms/group/FormGroup";
import Load from "../../../../../components/Layout/Load";
import { Creators as formInputsActions } from "../../../../../store/ducks/formInputs";
import { Creators as messageAction } from "../../../../../store/ducks/messages";

const isValidInput = (value) => {
  if (value !== undefined && value !== "" && value !== null) {
    return true;
  }
  return false;
};

export const buildDate = (date) => {
  if (date) {
    const dateItems = date.split("/");
    const day = dateItems[0];
    const month = dateItems[1];
    const year = dateItems[2];
    return new Date(`${year}-${month}-${day}`);
  }
  return new Date();
};

export const isValidDate = (date) => {
  return moment(date, "DD/MM/YYYY").isValid();
};

export const isValidEmitionDate = (value) => {
  const actualDate = new Date();
  let tempEmition;
  if (actualDate) {
    tempEmition = buildDate(value);
  }
  const emition = tempEmition;
  if (emition) {
    if (emition < actualDate) return true;
  }
  return false;
};

const renderInputLabel = (id, required) => {
  return (
    <LabelSpan>
      {required ? (
        <FormattedMessageWithStyle id={id} style={{ color: "black" }} />
      ) : (
        <FormattedMessage id={id} />
      )}

      {required ? <RequiredSpan /> : null}
    </LabelSpan>
  );
};

const EditCandidate = ({  
  requestUpdateCandidate,
  requestAdmissionCandidate,
  candidate,
  formInputsRequest,
  informacoesCandidatoColigadaRequest,
  isLoading,
  messagePublication,
  searchAddressByZipCode,
  addressData,
  location,
  informacoesCandidatoRequest,
  updated,
  coligadaCandidatoList,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showNecessaryDataFilledInfo, setShowNecessaryDataFilledInfo] = useState(false);
  const [mobile, setMobileView] = useState(false);
  const [countEditions, setCountEditions] = useState(0);
  const [idCandidato, setIdCandidato] = useState();
  const [errorMessages, setErrorMessages] = useState([]);
  const [dataNecessaryCompleted, setDataNecessaryCompleted] = useState();
  const [formErros, setFormErros] = useState([]);
  const [possuiVarivel, setPossuiVarivel] = useState(0);
  const [aceitoValeTransporte, setAceitoValeTransporte] = useState(0);
  const [submited, setSubmited] = useState(false);
  const [coligadas, setColigadas] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    cpf: "",
    matricula: "",
    dataAdmissao: "",
    phone1: "",
    email: "",
    coligadaCandidato: "",
    cargo: "",
    salario: "",
    address: {},
    number: "",
    neighborhood: "",
    refeicao: "",
    valeTransporte: [],
    numberOfTickets: 0,
    variavel: "",
  });

  const updateResize = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateResize);
    updateResize();
    const name = localStorage.getItem("name");
    setFormValues({ ...formValues, name });
    setCountEditions(0);
    
    let path = location.pathname.replace(/^\//gi, "").split("/");
    let id = path[path.length - 1];
    setIdCandidato(id);
    informacoesCandidatoRequest({ id });
  }, []);

  useEffect(() => {
    if (candidate != undefined) {
      setFormValues({ ...candidate });
      if (candidate.variavel !== "" && candidate.variavel !== null && candidate.variavel !== undefined) {
        setPossuiVarivel(1);
      }
      setDataNecessaryCompleted(candidate.dataNecessaryCompleted);
    }
  }, [candidate]);

  useEffect(() => {
    if (coligadaCandidatoList != undefined && coligadaCandidatoList.length > 0) {
      var options = coligadaCandidatoList.map((e, index) => ({
        value: e.id,
        label: `${e.nome ? e.nome : e.nomeFantasia}`,
      }));
      setColigadas(options);
    }
  }, [coligadaCandidatoList]);

  useEffect(() => {
    if (updated) {
      informacoesCandidatoRequest({ id: formValues.id });
    }
  }, [updated]);
  
  useEffect(() => {
    if (dataNecessaryCompleted != undefined && !dataNecessaryCompleted) {
      setShowNecessaryDataFilledInfo(true);
      setShowDialog(true);
    }
  }, [isLoading]);

  useEffect(() => {
    informacoesCandidatoColigadaRequest();
  }, []);

  useEffect(() => {
    if (addressData === undefined || Object.keys(addressData).length === 0)
      return;

    clearAddress();

    if (addressData.erro) return;

    let street = addressData.logradouro;
    
    setStateAddress("place", street);

    setStateAddress("neighborhood", addressData.bairro);
  }, [addressData]);

  const clearAddress = () => {
    setStateAddress("street", "");
    setStateAddress("number", "");
    setStateAddress("place", "");
    setStateAddress("neighborhood", "");
  };

  const updateCandidate = () => {
    let formValuesToUpdate = formValues;
    requestUpdateCandidate(formValuesToUpdate);
  };

  const admissionCandidate = () => {
    requestAdmissionCandidate({ id: candidate.id });
  };

  const updateAdmissionCandidate = () => {
    let errosList = [];

    if (!isValidInput(formValues.name)) {
      errosList.push({
        type: "warning",
        message: "Nome do colaborador precisa ser preenchido.",
      });
    }
    if (possuiVarivel == 1){
      if (!isValidInput(formValues.variavel)) {
        errosList.push({
          type: "warning",
          message: "Varivel precisa ser preenchida.",
        });
      }
    }
    if (!isValidInput(formValues.matricula)) {
      errosList.push({
        type: "warning",
        message: "Matrícula precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.dataAdmissao)) {
      errosList.push({
        type: "warning",
        message: "Data Admissão precisa ser preenchido.",
      });
    }
    if (
      !isValidInput(formValues.phone1) ||
      formValues.phone1 === "(__)_ ____-____"
    ) {
      errosList.push({
        type: "warning",
        message: "O telefone precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.email)) {
      errosList.push({
        type: "warning",
        message: "Email precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.coligadacandidato)) {
      errosList.push({
        type: "warning",
        message: "Operação precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.cargo)) {
      errosList.push({
        type: "warning",
        message: "Cargo precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.salario)) {
      errosList.push({
        type: "warning",
        message: "Salário precisa ser preenchido.",
      });
    }
    if (
      !isValidInput(formValues.address.zipCode) ||
      formValues.address.zipCode === "_____-___"
    ) {
      errosList.push({
        type: "warning",
        message: "O CEP precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.address.place)) {
      errosList.push({
        type: "warning",
        message: "Endereço precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.address.number)) {
      errosList.push({
        type: "warning",
        message: "Número do Endereço precisa ser preenchido.",
      });
    }
    if (!isValidInput(formValues.address.neighborhood)) {
      errosList.push({
        type: "warning",
        message: "Bairro precisa ser preenchido.",
      });
    }
    if (errosList.length > 5) {
      messagePublication([
        {
          type: "warning",
          message:
            "Preencha todos os dados antes de enviar os dados do funcionario para o RM.",
        },
      ]);
      return;
    }
    if (errosList.length > 0) {
      messagePublication(errosList);
      return;
    }
    admissionCandidate();
  };

  const updateUserInformation = () => {
    let errosList = [];
    if (possuiVarivel == 0){
      setFormValues({ ...formValues, variavel: "" });
    }
    if (errosList.length > 0) {
      messagePublication(errosList);
      return;
    }
    updateCandidate();
  };

  const setStateAddress = (key, value) => {
    const actualState = formValues;
    actualState.address[key] = value;
    setFormValues({ ...actualState });
  };

  const searchAddress = (value) => {
    setStateAddress("zipCode", value);

    const zipCode = value.replace(/\.|\-/g, "");
    const validateZipCode = /^[0-9]{8}$/;

    if (zipCode != "" && validateZipCode.test(zipCode))
      searchAddressByZipCode({ zipCode: zipCode });
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value.replace(/[^0-9]/g, "")) / 100;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);
  };

  const handleChangeCurrency = (e, fieldName) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value) {
      value = formatCurrency(value);
    }

    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <Body
      style={{
        width: "70%",
        padding: "2",
      }}
    >
      <BreadCrumb location={location} title="Colaborador" />
      {!dataNecessaryCompleted && (
        <Row m={0}>
          {!mobile && (
            <Col style={{ paddingLeft: "18px" }} xs={12} lg={12}>
              <H1 color={"red"} size={"1.1rem"} weight={12}>
                {<FormattedMessage id="sharedItems.Mandatory.title" />}
              </H1>
            </Col>
          )}
        </Row>
      )}
      <FormGroup formName="Dados Perfil">
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} lg={7}>
            <TextField
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  name: e.target.value.replace(/[^a-zA-ZÀ-Úà-ú. ]/g, ""),
                })
              }
              mb="1"
              value={formValues.name}
              label={renderInputLabel(
                "sharedItems.name",
                !dataNecessaryCompleted
              )}
            />
          </Col>
          <Col md={4} lg={5}>
            <InputMask
              mask={
                formValues.cpf.length > 11
                  ? "99.999.999/9999-99"
                  : "999.999.999-99"
              }
              type="text"
              label={renderInputLabel( formValues.cpf.length > 11 ? "sharedItems.CNPJ" : "sharedItems.CPF")}
              value={formValues.cpf}
              disabled
              mb="1"
              alwaysShowMask
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={5}>
            <TextField
              type="text"
              label={renderInputLabel(
                "sharedItems.enrollment",
                !dataNecessaryCompleted
              )}
              value={formValues.matricula}
              onChange={(e) =>
                setFormValues({ ...formValues, matricula: e.target.value })
              }
              mb="1"
            />
          </Col>
          <Col lg={4}>
            <DatePicker
              style={{ boxShadow: "#A5A5A5" }}
              width="223px"
              value={formValues.dataAdmissao}
              label={renderInputLabel(
                "sharedItems.dataAdmissao",
                !dataNecessaryCompleted
              )}
              max="2099-12-31"
              min="2023-01-01"
              onChange={(e) => {
                const year = e.target.value.split("-")[0];
                if (year === "" || parseInt(year) > 2099) return;

                setFormValues({
                  ...formValues,
                  dataAdmissao: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <InputMask
              mask="(99) 9 9999-9999"
              mb="1"
              type="tel"
              value={formValues.phone1}
              onChange={(e) =>
                setFormValues({ ...formValues, phone1: e.target.value })
              }
              label={renderInputLabel(
                "sharedItems.phone1",
                !dataNecessaryCompleted
              )}
              alwaysShowMask
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col lg={6}>
            <TextField
              onChange={(e) => {
                messagePublication([
                  {
                    type: "warning",
                    message:
                      "Ao continuar a alteração no e-mail, será necessário realizar novo login para validar a atualização.",
                  },
                ]);
                setFormValues({ ...formValues, email: e.target.value });
              }}
              mb="1"
              value={formValues.email}
              label={renderInputLabel(
                "sharedItems.email",
                !dataNecessaryCompleted
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <SelectInput
              options={coligadas}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  coligadaCandidato: e.value,
                });
              }}
              value={coligadas.find(
                (e) => e.value == formValues.coligadaCandidato
              )}
              label={<FormattedMessage id="sharedItems.operation" />}
            />
          </Col>
          <Col md={3}>
            <TextField
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cargo: e.target.value,
                })
              }
              mb="1"
              value={formValues.cargo}
              label={renderInputLabel(
                "sharedItems.job",
                !dataNecessaryCompleted
              )}
            />
          </Col>
          <Col md={3}>
            <TextField
              placeholder="R$000,00"
              value={formValues.salario}
              mb="1"
              label={renderInputLabel(
                "sharedItems.salario",
                !dataNecessaryCompleted
              )}
              type="text"
              min={0.0}
              step={0.01}
              onChange={(e) => {
                handleChangeCurrency(e, "salario");
              }}
              alwaysShowMask
            />
          </Col>
          <Col md={2}>
            <Flag>
              <FormattedMessage id="Possui Variável?" />
            </Flag>
            <Row>
              {YesAndNo?.map((option, index) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} key={index}>
                    <RadioInput
                      label={option.label}
                      value={option.value || ""}
                      selectedOption={possuiVarivel}
                      checked={possuiVarivel === option.value}
                      mb={"1em"}
                      onChange={() => setPossuiVarivel(option.value)}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row>
          {possuiVarivel != 0 && (
            <Col sm={12} lg={4}>
              <TextField
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    variavel: e.target.value.replace(/[^0-9.,%R$]/g, ""),
                  })
                }
                mb="1"
                value={formValues.variavel}
                label={renderInputLabel("Variável", !dataNecessaryCompleted)}
              />
            </Col>
          )}
        </Row>
      </FormGroup>
      <div>
        {errorMessages.length > 0 && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessages([])}
            dismissible
          >
            <ul>
              {errorMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </Alert>
        )}
        <style>
          {`
          .alert ul {
            margin: 0;
            padding-left: 20px;
          }
          .alert li {
            list-style: none;
          }
        `}
        </style>
      </div>
      <FormGroup formName="Endereço colaborador">
        <Row style={{ marginTop: !mobile && "20px" }}>
          <Col lg={3}>
            <InputMask
              mask="99999-999"
              type="tel"
              mb="1"
              value={formValues.address.zipCode || ""}
              onChange={(e) => searchAddress(e.target.value)}
              label={renderInputLabel(
                "sharedItems.cep",
                !dataNecessaryCompleted
              )}
              alwaysShowMask
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col lg={6}>
            <TextField
              onChange={(e) => setStateAddress("place", e.target.value)}
              value={formValues.address.place || ""}
              mb="1"
              label={renderInputLabel(
                "sharedItems.address",
                !dataNecessaryCompleted
              )}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: !mobile && "20px" }}>
          <Col lg={2}>
            <TextField
              mb="1"
              onChange={(e) =>
                setStateAddress(
                  "number",
                  e.target.value.replace(/[^0-9. ]/g, "")
                )
              }
              value={formValues.address.number || ""}
              label={renderInputLabel(
                "sharedItems.number",
                !dataNecessaryCompleted
              )}
            />
          </Col>
          <Col lg={4}>
            <TextField
              mb="1"
              onChange={(e) => setStateAddress("neighborhood", e.target.value)}
              value={formValues.address.neighborhood || ""}
              label={renderInputLabel(
                "sharedItems.neighborhood",
                !dataNecessaryCompleted
              )}
            />
          </Col>
        </Row>
        <RenderModal
          show={showDialog}
          setShowDialog={setShowDialog}
          updateUserInformation={updateUserInformation}
          showNecessaryDataFilledInfo={showNecessaryDataFilledInfo}
          buttonEventAction={
            showNecessaryDataFilledInfo
              ? () => setShowNecessaryDataFilledInfo(false)
              : updateUserInformation
          }
        />
      </FormGroup>
      <div
        style={{
          border: `1px solid ${theme.colors.ligthGray}`,
          margin: "15px 1px",
          padding: "10px",
        }}
      >
        <Col md={8}>
          <Row style={{ marginBottom: "10px" }}>
            <Col md={5}>
              <LabelH1 fz="1.5rem">Vale Refeição: </LabelH1>
              {YesAndNo.map((option, index) => (
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ padding: "7px" }}
                  key={index}
                >
                  <RadioInput
                    label={option.label}
                    value={option.value}
                    selectedOption={
                      formValues.refeicao != undefined &&
                      formValues.refeicao != 0 &&
                      formValues.refeicao != null &&
                      formValues.refeicao != ""
                        ? 1
                        : 0
                    }
                    mb={"1em"}
                    disabled
                  />
                </Col>
              ))}
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <LabelH1 fz="1.5rem">Vale Transporte: </LabelH1>
              {YesAndNo.map((option, index) => (
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ padding: "7px" }}
                  key={index}
                >
                  <RadioInput
                    label={option.label}
                    value={option.value}
                    selectedOption={
                      formValues.valeTransporte.length > 0 ? 1 : 0
                    }
                    mb={"1em"}
                    disabled
                  />
                </Col>
              ))}
            </Col>
          </Row>
        </Col>
      </div>
      <Row>
        <Col>
          <FixedAction>
            <ButtonDefault
              name={<FormattedMessage id="sharedItems.save" />}
              onClick={updateUserInformation}
              isLoading={false}
              width="fit-content"
            />
          </FixedAction>
        </Col>
        <Col>
          <FixedAction>
            <ButtonDefault
              name={<FormattedMessage id="sharedItems.completeAdmission" />}
              onClick={() => updateAdmissionCandidate()}
              isLoading={false}
              width="fit-content"
              disabled={!dataNecessaryCompleted}
            />
          </FixedAction>
        </Col>
      </Row>
    </Body>
  );
};

const RenderModal = ({
  show,
  setShowDialog,
  showNecessaryDataFilledInfo,
  buttonEventAction,
}) => {
  return (
    <Modal centered={true} size="xl" show={show}>
      <Modal.Header>
        <Title
          size="1.500em"
          color="title"
          title={
            showNecessaryDataFilledInfo ? (
              <FormattedMessage id="sharedItems.myProfile.ModalNecessaryDataFilled.title" />
            ) : (
              <FormattedMessage id="sharedItems.myProfile.ModalEmailChange.title" />
            )
          }
        />
      </Modal.Header>
      <Modal.Body>
        {showNecessaryDataFilledInfo ? (
          <FormattedMessage id="sharedItems.myProfile.ModalNecessaryDataFilled.info" />
        ) : (
          <FormattedMessage id="sharedItems.myProfile.ModalEmailChange.info" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col>
          <ButtonDefault
            onClick={() => {
              buttonEventAction();
              setShowDialog(false);
            }}
            name={
              <FormattedMessage id="sharedItems.myProfile.ModalEmailChange.continue" />
            }
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  goTo: (route) => dispatch(push(route)),
  createCandidate: (candidate) =>
    dispatch(candidateActions.candidatesCreateCandidateRequest(candidate)),
  informacoesCandidatoRequest: (payload) =>
    dispatch(candidateActions.informacoesCandidatoRequest(payload)),
  requestUpdateCandidate: (payload) =>
    dispatch(candidateActions.candidateProfileUpdateRequest(payload)),
  requestAdmissionCandidate: (payload) =>
    dispatch(candidateActions.candidateAdmissionRequest(payload)),
  informacoesCandidatoColigadaRequest: () =>
    dispatch(candidateActions.informacoesCandidatoColigadaRequest()),
  candidateGetProfileRequest: () =>
    dispatch(candidateActions.candidatesGetProfileRequest()),
  requestUpdateAvatar: (file) =>
    dispatch(candidateActions.candidateAvatarUpdateRequest(file)),
  deleteAvatar: () => dispatch(candidateActions.deleteAvatar()),
  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),
  getWorkplaces: () => dispatch(formInputsActions.formWorkplaceRequest()),
  requestChangePhoneNumber: (payload) =>
    dispatch(candidateActions.candidateChangePhoneNumberRequest(payload)),
  searchAddressByZipCode: (payload) =>
    dispatch(formInputsActions.searchAddressByZipCodeRequest(payload)),
});

const mapStateToProps = (state) => ({
  user: state.login,
  candidate: state.candidates.candidate,
  formInputs: state.formInputs,
  formInputsLoading: state.formInputs.isLoading,
  isLoading: state.candidates.isLoading,
  requiredFields: state.candidates.requiredFields,
  workplaces: state.formInputs.workplaces,
  hasPendingStage: state.candidates.hasPendingStage,
  pendingStageType: state.candidates.pendingStageType,
  pendingStageId: state.candidates.pendingStageId,
  phoneNumberChanged: state.candidates.phoneNumberChanged,
  coligadaCandidatoList: state.candidates.coligadaCandidato,
  addressData: state.formInputs.addressData,
  updated: state.candidates.updated,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidate);