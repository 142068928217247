import theme from "../../../../components/shared/Theme";
export const DefaultDashStyle = [
  {
    label: "Documentação",
    data: [
      {
        id: new Date().getTime(),
        type: "iconcard",
        title: "",
        style: {
          column: `merge-0`,
          row: "merge-row-0",
        },
        customStyle: {
          color: "var(--red)",
          icon: "ic_schedule_send",
        },
        data: "-",
        attribute: "totalCandidatosAdmitidos",
        value: "totalRegistros",
        titleValue: "nome",
      },
      {
        id: new Date().getTime(),
        type: "iconcard",
        title: "",
        style: {
          column: `merge-0`,
          row: "merge-row-0",
        },
        customStyle: {
          color: "var(--green)",
          icon: "ic_file_download_done",
        },
        data: "-",
        attribute: "totalCandidatosDesistentes",
        value: "totalRegistros",
        titleValue: "nome",
      },
      {
        id: new Date().getTime(),
        type: "iconcard",
        title: "",
        style: {
          column: `merge-0`,
          row: "merge-row-0",
        },
        customStyle: {
          color: "var(--yellow)",
          icon: "ic_folder",
        },
        data: "-",
        attribute: "totalDocumentosPendenteDeEnvio",
        value: "totalRegistros",
        titleValue: "nome",
      },
      {
        id: new Date().getTime(),
        type: "chartcard",
        title: "Status por Casa",
        style: {
          column: `merge-2`,
          row: "merge-row-0",
        },
        customStyle: {
          color: theme.colors.defaultColor,
          height: "auto",
        },
        data: "",
        attribute: "totalCandidatosPorSiteStatus",
        value: "totalRegistros",
        titleValue: "nome",
        labelsValues: [
          "totalCandidatosAdmitidos",
          "totalCandidatosDesistentes",
          "totalDocumentosPendenteDeEnvio",
        ],
      },
    ],
  },
];