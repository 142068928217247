import BreadCrumb from "components/Layout/breadcrumb";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import { push } from "connected-react-router";
import { LabelH1 } from "../../../../../components/shared/CustomComponents";
import { Creators as candidateActions } from "../../../../../store/ducks/candidates";
import {
  ButtonSecondary,
  RadioInput,
  SelectInput,
  TextField,
  DatePicker,
  ButtonDefault,
} from "components/shared/CustomInputs";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import FormGroup from "../../../../../components/Forms/group/FormGroup";
import theme from "../../../../../components/shared/Theme";
import { required } from "../../../../../helpers/validations";
import { YesAndNo, TiposDeUser } from "../../../../../utils/contants/enums";
import { Body, Span } from "./NewCandidateStyle";

import { Creators as messageAction } from "store/ducks/messages";
import { FormattedMessageWithStyle } from "../../../../../components/shared/CustomComponents";
import { LabelSpan, RequiredSpan } from "../EditCandidate/EditCandidateStyle";
import ApiViaCEP from "../../../../../utils/ApiViaCep";
import { formatErrorsMessages } from "../../../../../helpers/sharedHelpers";
import Load from "components/Layout/Load";

const renderInputLabel = (id, required) => {
  return (
    <LabelSpan>
      {required ? (
        <FormattedMessageWithStyle id={id} style={{ color: "#005394" }} />
      ) : (
        <FormattedMessage id={id} />
      )}

      {required ? <RequiredSpan /> : null}
    </LabelSpan>
  );
};

const ValeTransporteFields = ({
  formValues,
  setFormValues,
  transportadorasIdx,
  key,
  requiredFields,
  removeValeTransporte,
  aceitoValeTransporte,
}) => {
  const [transportadoras, setTransportadoras] = useState(
    formValues.valeTransporte
  );

  const updateFormValues = (transportadoraId) => {
    setFormValues({ ...formValues, valeTransporte: transportadoras });
  };

  useEffect(() => {
    setFormValues({
      ...formValues,
      numberOfTickets: formValues.valeTransporte.length,
    });
  }, [formValues.valeTransporte.length]);

  useEffect(() => {

    const teste = {...(aceitoValeTransporte === 1 ? [required] : [])}

  }, [aceitoValeTransporte]);


  return (
    <Row
      md={12}
      style={{
        border: `1px solid ${theme.colors.ligthGray}`,
        margin: "15px 1px",
        padding: "10px",
      }}
    >
      <Row>
        <Col md={6}>
          <TextField
            min={0.0}
            step={0.01}
            label={<FormattedMessage id="sharedItems.companyName" />}
            onChange={(e) => {
              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].nome = e.target.value;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              ).nome
            }
          />
        </Col>
        <Col md={6}>
          <TextField
            placeholder="R$ ..."
            pattern="[0-9]*,[0-9]{2}"
            min={0.0}
            step={0.5}
            label={<FormattedMessage id="sharedItems.ticketValue" />}
            validations={[required]}
            onChange={(e) => {
              let money = e.target.value;

              money = money.replace(/\D/g, "");

              money = (parseFloat(money) / 100).toFixed(2).replace(".", ",");

              money = `R$ ${money}`;

              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].valor = money;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              )?.valor
            }
          />
        </Col>
        <Col md={6}>
          <TextField
            pattern="[0-9]*,[0-9]{2}"
            min={0.0}
            step={1}
            label={<FormattedMessage id="sharedItems.amountOfTickets" />}
            onChange={(e) => {

              const numericValue = e.target.value.replace(/[^0-9]/g, "");

              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].passagens =
              numericValue;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              )?.passagens
            }
          />
        </Col>
        <Col md={6}>
          <Button
            variant="danger"
            style={{ marginTop: "18px" }}
            onClick={(e) => {
              let transportadoraId = removeValeTransporte(transportadorasIdx);
            }}
          >
            Remover
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

const NewCandidate = ({
  location,
  createCandidate,
  createCandidateBulk,
  messagePublication,
  ProjetoListAll,
  tomador,
  informacoesCandidatoColigadaRequest,
  coligadaCandidato,
  listaDeImportacao,
  candidateInformationRmSecaoListRequest,
  isLoading,
}) => {
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [createdCandidate, setCreatedCandidate] = useState(false);
  const [codSecaoOptions, setCodSecaoOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  //implementado especificamente para o chalezinho
  const [aceitoValeRefeicao, setAceitoValeRefeicao] = useState(0);
  const [aceitoValeTransporte, setAceitoValeTransporte] = useState(0);
  const [dataNecessaryCompleted, setDataNecessaryCompleted] = useState();
  const [formValues, setFormValues] = useState({
    nome: "",
    cep: "",
    rua: "",
    numero: "",
    bairro: "",
    identificador: "",
    email: "",
    telefone: "",
    cargo: "",
    matricula: "",
    dataAdmissao: "",
    salario: "",
    tomador: "",
    coligadaCandidato: "",
    saldoRefeicao: "",
    valeTransporte: [],
    numberOfTickets: 0,
    diasUteis: "",
  });
  const [cadastroPessoaFisica, setCadastroPessoaFisica] = useState(true);

  const addValeTransporte = () => {
    let vale = formValues.valeTransporte;
    vale.push({
      idx: formValues.valeTransporte.length,
      nome: "",
      passagens: "",
      valor: "",
    });

    setFormValues({
      ...formValues,
      valeTransporte: vale,
    });
  };

  const removeValeTransporte = (transportadorasIdx) => {
    let transportadoraId = 0;

    const TransportadoraIndex = formValues.valeTransporte.findIndex(
      (transportadora) => transportadora.idx === transportadorasIdx
    );
    transportadoraId = formValues.valeTransporte[transportadorasIdx].idx;

    let listaValeTransporte = formValues.valeTransporte;
    listaValeTransporte.splice(TransportadoraIndex, 1);

    listaValeTransporte = listaValeTransporte.map((transportadora, index) => ({
      ...transportadora,
      idx: index,
    }));

    setFormValues({
      ...formValues,
      valeTransporte: listaValeTransporte,
    });
  };

  useEffect(() => {}, [listaDeImportacao]);

  const sendValues = async (e) => {
    const cep = e.target.value;
    setFormValues((prevValues) => ({ ...prevValues, cep }));

    if (cep.length === 9) {
      try {
        const response = await ApiViaCEP.get(`/${cep}/json`);
        const { logradouro, bairro, uf } = response.data;

        setFormValues((prevValues) => ({
          ...prevValues,
          rua: logradouro,
          bairro: bairro,
          estado: uf,
        }));
      } catch (error) {
        console.error("Erro ao buscar o CEP:", error);
      }
    } else if (cep.length === 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        rua: "",
        bairro: "",
        estado: "",
      }));
    }
  };
  // aqui
  const save = (candidateData) => {
    if (!Object.keys(formErros).length) {
      createCandidate({ ...candidateData, EhPessoaFisica: cadastroPessoaFisica });
      setCreatedCandidate(true);
      limpar();
    } else {
      const formErrosMessages = formatErrorsMessages(formErros);
      messagePublication(formErrosMessages);
    }
  };

  const limpar = () => {
    setFormValues({
      nome: "",
      cep: "",
      rua: "",
      numero: "",
      bairro: "",
      identificador: "",
      email: "",
      telefone: "",
      cargo: "",
      matricula: "",
      dataAdmissao: 0,
      salario: "",
      tomador: "",
      coligadaCandidato: "",
      saldoRefeicao: "",
      valeTransporte: [],
      numberOfTickets: 0,
      diasUteis: "",
    });
    setSelectedFile(null);
  };

  useEffect(() => {
    informacoesCandidatoColigadaRequest();
  }, []);

  useEffect(() => {
    setFormValues({
      nome: "",
      cep: "",
      rua: "",
      numero: "",
      bairro: "",
      identificador: "",
      email: "",
      telefone: "",
      cargo: "",
      matricula: "",
      dataAdmissao: 0,
      salario: "",
      tomador: "",
      coligadaCandidato: "",
      saldoRefeicao: "",
      valeTransporte: [],
      numberOfTickets: 0,
      diasUteis: "",
    });
  }, [createdCandidate]);

  useEffect(() => {
    if (ProjetoListAll && ProjetoListAll.length > 0) {
      const options = ProjetoListAll.map((e) => ({
        value: e.id,
        label: e.nome,
      }));
      setCodSecaoOptions(options);
    }
  }, [ProjetoListAll]);

  const formatCurrencyMask = (value) => {
    if (!value) return "";

    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) return "";

    const cleanedValue = numericValue.replace(/^0+/, "");
    const integerPart = cleanedValue.slice(0, -2) || "0";
    const decimalPart = cleanedValue.slice(-2).padStart(2, "0");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `R$ ${formattedInteger},${decimalPart}`;
  };

  const handleChangeCurrency = (e, fieldName) => {
    const rawValue = e.target.value;

    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: formatCurrencyMask(rawValue),
    }));
  };

      
  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb location={location} title="Selecione o colaborador" />
      <Body>
        <FormGroup formName="Cadastro de novo colaborador">
          <Row>
            <Col
              md={6}
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {TiposDeUser?.map((option, index) => {
                return (
                  <RadioInput
                    label={option.label}
                    value={option.value || ""}
                    selectedOption={cadastroPessoaFisica}
                    checked={cadastroPessoaFisica === option.value}
                    onChange={() => setCadastroPessoaFisica(option.value)}
                    key={index}
                    mb={4}
                    colCheckBox={3}
                    colLabel={9}
                  />
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    nome: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.name" />}
                value={formValues.nome}
                name="Nome"
              />
            </Col>
            <Col md={3}>
              <InputMask
                mask={
                  cadastroPessoaFisica ? "999.999.999-99" : "99.999.999/9999-99"
                }
                placeholder={
                  cadastroPessoaFisica ? "000.000.000-00" : "00.000.000/0000-00"
                }
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                maskChar={null}
                alwaysShowMask
                validations={[required]}
                name={"identificador"}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    identificador: event.target.value,
                  })
                }
                label={
                  <FormattedMessage
                    id={
                      cadastroPessoaFisica
                        ? "sharedItems.CPF"
                        : "sharedItems.CNPJ"
                    }
                  />
                }
                value={formValues.identificador}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
            </Col>
            <Col md={3}>
              {/* aqui meu fio  */}
              <TextField
                validations={cadastroPessoaFisica ? [required] : []}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    matricula: event.target.value,
                  })
                }
                label={<FormattedMessage id="NewCandidatesVacancy.matricula" />}
                value={formValues.matricula}
                name="Mátricula"
              />
            </Col>
            <Col md={3}>
              <DatePicker
                name="Data Admissão"
                style={{ boxShadow: "#A5A5A5" }}
                width="223px"
                value={formValues.dataAdmissao}
                formErr={formErros}
                setFormErros={setFormErros}
                touched={submited}
                formValidators={setFormValidators}
                validations={[required]}
                label={renderInputLabel(
                  "sharedItems.dataAdmissao",
                  !dataNecessaryCompleted
                )}
                max="2099-12-31"
                min="2023-01-01"
                onChange={(e) => {
                  const year = e.target.value.split("-")[0];
                  if (year === "" || parseInt(year) > 2099) return;

                  setFormValues({
                    ...formValues,
                    dataAdmissao: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <InputMask
                name="Cep"
                mask="99999-999"
                placeholder="00000-000"
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                validations={[required]}
                maskChar={null}
                alwaysShowMask
                onChange={(e) => sendValues(e)}
                value={formValues.cep}
                label={<FormattedMessage id="sharedItems.zipCode" />}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
            </Col>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    rua: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.street" />}
                value={formValues.rua}
                name="Rua"
              />
            </Col>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    numero: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.streetNumber" />}
                value={formValues.numero}
                name="Número"
              />
            </Col>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    bairro: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.neighborhood" />}
                value={formValues.bairro}
                name="Bairro"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <SelectInput
                name="Operação"
                options={Object.values(coligadaCandidato).map((e) => ({
                  value: e.id,
                  label: e.nome || e.nomeFantasia,
                }))}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    coligadaCandidato: e.value,
                  });
                }}
                value={
                  formValues.coligadaCandidato
                    ? {
                        value: formValues.coligadaCandidato || "",
                        label:
                          Object.values(coligadaCandidato).find(
                            (e) => e.id === formValues.coligadaCandidato
                          )?.nome || "",
                      }
                    : null
                }
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                validations={[required]}
                label={<FormattedMessage id="sharedItems.operation" />}
              />
            </Col>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    cargo: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.role" />}
                value={formValues.cargo}
                name="Cargo"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="Salário"
                placeholder="R$ 0,00"
                value={formValues.salario || ""}
                onChange={(e) => handleChangeCurrency(e, "salario")}
                label={<FormattedMessage id="sharedItems.salario" />}
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <TextField
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    email: event.target.value,
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="sharedItems.email" />}
                value={formValues.email}
                name="Email"
              />
            </Col>
            <Col md={3}>
              <InputMask
                name="Telefone"
                mask="(99) 9 9999-9999"
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                validations={[required]}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    telefone: event.target.value,
                  })
                }
                label={<FormattedMessage id="sharedItems.phone" />}
                value={formValues.telefone}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
            </Col>
            <Col md={3}>
              <InputMask
                name="Dias Uteis"
                mask="99"
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                validations={[required]}
                maskChar={null}
                alwaysShowMask
                onChange={(event) => {
                  let dias = event.target.value;
                  dias = dias.toString().replace("_", "").trim();

                  if (parseInt(dias) > 0 && parseInt(dias) < 30) {
                    setFormValues({
                      ...formValues,
                      diasUteis: dias,
                    });
                  }
                }}
                label={<FormattedMessage id="sharedItems.workingDays" />}
                value={formValues.diasUteis}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
            </Col>
          </Row>
          <div
            style={{
              border: `1px solid ${theme.colors.ligthGray}`,
              margin: "15px 1px",
              padding: "10px",
            }}
          >
            <Col md={8}>
              <Row style={{ marginBottom: "10px" }}>
                <Col md={5}>
                  <LabelH1 fz="1.5rem">Vale Refeição: </LabelH1>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                      padding: "7px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {YesAndNo?.map((option, index) => {
                      return (
                        <RadioInput
                          label={option.label}
                          value={option.value || ""}
                          selectedOption={aceitoValeRefeicao}
                          checked={aceitoValeRefeicao === option.value}
                          mb={"1em"}
                          onChange={() => setAceitoValeRefeicao(option.value)}
                          key={index}
                        />
                      );
                    })}
                  </Col>
                  {aceitoValeRefeicao === 1 && (
                    <TextField
                      placeholder="R$ ..."
                      pattern="[0-9]*,[0-9]{2}"
                      min={0.0}
                      step={0.01}
                      setFormErros={setFormErros}
                      formValidators={setFormValidators}
                      validations={[
                        ...(aceitoValeRefeicao === 1 ? [required] : []),
                      ]}
                      touched={submited}
                      formErr={formErros}
                      label={<FormattedMessage id="sharedItems.mealBalance" />}
                      value={formValues.saldoRefeicao || ""}
                      mb="1"
                      name="refeição"
                      onChange={(e) => handleChangeCurrency(e, "saldoRefeicao")}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <LabelH1 fz="1.5rem">Vale Transporte: </LabelH1>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                      padding: "7px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {YesAndNo?.map((option, index) => {
                      return (
                        <RadioInput
                          label={option.label}
                          value={option.value || ""}
                          selectedOption={aceitoValeTransporte}
                          checked={aceitoValeTransporte === option.value}
                          mb={"1em"}
                          onChange={() => setAceitoValeTransporte(option.value)}
                          key={index}
                        />
                      );
                    })}
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                {aceitoValeTransporte === 1 && (
                  <>
                    <ButtonDefault
                      name={<FormattedMessage id="sharedItems.addTransport" />}
                      onClick={() => addValeTransporte()}
                      width="100%"
                      isLoading={false}
                    />

                    {formValues.valeTransporte.length > 0 &&
                      formValues.valeTransporte.map((dependent, index) => {
                        return (
                          <ValeTransporteFields
                            formValues={formValues}
                            setFormValues={setFormValues}
                            transportadorasIdx={index}
                            key={index}
                            requiredFields={!dataNecessaryCompleted}
                            removeValeTransporte={removeValeTransporte}
                            aceitoValeTransporte={aceitoValeTransporte}
                          />
                        );
                      })}
                  </>
                )}
              </Row>
            </Col>
          </div>
          <Row
            style={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => save(formValues)}
                name={<FormattedMessage id="sharedItems.save" />}
              />
            </Col>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary onClick={() => limpar()} name="Limpar" />
            </Col>
          </Row>
        </FormGroup>
      </Body>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  goTo: (route) => dispatch(push(route)),

  createCandidate: (candidate) =>
    dispatch(candidateActions.candidatesCreateCandidateRequest(candidate)),

  createCandidateBulk: (candidatesBulk) =>
    dispatch(
      candidateActions.candidatesCreateCandidateBulkRequest(candidatesBulk)
    ),

  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  informacoesCandidatoColigadaRequest: () =>
    dispatch(candidateActions.informacoesCandidatoColigadaRequest()),

  candidateInformationRmSecaoListRequest: (payload) =>
    dispatch(candidateActions.candidateInformationRmSecaoListRequest(payload)),
});

const mapStateToProps = (state) => ({
  coligadaCandidato: state.candidates.coligadaCandidato,
  ProjetoListAll: state.candidates.secaoInfo,
  listaDeImportacao: state.candidates.candidatesBulk,
  isLoading: state.candidates.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCandidate);
