import React, { useState, useRef, useEffect } from "react";
import { dashboardUrls } from "../../../../utils/contants/urlsApi";
import {
  MainFrameDash,
  NotificationArea,
  Header,
} from "./DashBoardPlugMainStyle";

import DynamicDash from "../../../../components/DynamicDash";
import { connect } from "react-redux";

import { Button } from "../../../../components/shared/CustomInputs";
import { DefaultDashStyle } from "./DefaultDashboardStyle";
import DashAlert from "../../../../components/DynamicDash/DashAlert/DashAlert";
import { Creators as documentsAction } from "../../../../store/ducks/documents";
import { Creators as doubtsAction } from "store/ducks/doubts";
import Load from "../../../../components/Layout/Load";

const DashBoardPlugMain = ({
  isLoading,
  getDashDataInformation,
  dashInformations,
  getAllUserAllerts,
  AllUserAlerts,
  MarkAlertAsRead,
}) => {
  const [view, setView] = useState(0);
  const [dashBase, setDashBase] = useState(null);
  const [dashData, setDashData] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [notificationData, setNotificationData] = useState();
  const name = window.localStorage.getItem("name").split(" ")[0];
  const [modalStates, setModalStates] = useState([]);

  const closeModal = (index) => {
    const newModalStates = [...modalStates];
    newModalStates[index] = false;
    setModalStates(newModalStates);
  };

  let colorstemp = [];
  colorstemp.length = 100;
  colorstemp.fill(0);
  colorstemp = colorstemp.map((item) => {
    const color = "0123456789ABCDEF"
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("")
      .substring(0, 6);
    const rand = (frm, to) => {
      return ~~(Math.random() * (to - frm)) + frm;
    };
    return `rgba(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)}, 0.3)`;
  });

  const defaultColors = useRef();
  defaultColors.current = defaultColors.current
    ? defaultColors.current
    : colorstemp;

  const tabFilter = useRef(0);
  tabFilter.current = tabFilter.current === 0 ? 0 : tabFilter.current;

  //inicialização do estilo do dashboard
  const tabsView = useRef(DefaultDashStyle);

  const getDataDash = (_json) => {
    if (!_json) {
      setDashData({
        data: [],
        base: _json,
      });
      return [];
    }

    const json =
      filterList?.length > 0
        ? _json[filterList[tabFilter.current]?.value]
        : _json;

    let result = tabsView.current[~~view].data;

    if (!result) {
      return [];
    }

    result = result.map((item) => {
      const objBase = (json[item?.attribute] || {})[0];
      if (objBase) {
        if (/chart/gi.test(item.type)) {
          let list = item;

          item.data = {
            type: "bar",
            data: {
              labels: item?.labelsValues?.map(
                (n, idx) => json[n][0]?.nome?.toString() || `Campo - ${idx + 1}`
              ),
              datasets: [
                {
                  label: "item",
                  data: item?.labelsValues?.map(
                    (n, idx) => json[n][0]?.totalRegistros?.toString() || 0
                  ),
                  backgroundColor: [
                    "rgb(220 53 69)",
                    "rgb(40 167 69)",
                    "rgb(255 193 7)",
                    "rgb(253 126 20)",
                    "rgb(255 193 7)",
                    "rgba(153, 102, 255, 0.7)",
                    "rgba(201, 203, 207, 0.7)",
                  ],
                },
              ],
            },
            options: {
              indexAxis: "x",
              responsive: true,
              hoverOffset: 1,
              maintainAspectRatio: true,
              aspectRatio: 3.6,
              scales: {
                x: {
                  beginAtZero: true,
                  stacked: true,
                  grid: {
                    offset: true,
                  },
                },
                y: {
                  stacked: true,
                  beginAtZero: true, // Comece o eixo Y a partir de zero
                  suggestedMax: 100, // Defina um valor máximo sugerido
                  grid: {
                    offset: false,
                  },
                },
              },
              plugins: {
                datalabels: {
                  color: "#000",
                  anchor: "center",
                  offset: -20,
                  align: "left",
                  display: false,
                },
                legend: {
                  display: false,
                },
              },
            },
          };
        } else {
          item.data = objBase[item?.value]?.toString();
          item.title = objBase[item?.titleValue];
        }
      }

      return item;
    });

    setDashData({
      data: result,
      base: _json,
    });
  };

  const dataDashGetData = () => {
    try {
      getDashDataInformation();
    } catch (e) {
      console.error("Error:", e);
    }
  };

  const alterFilterTab = (_data) => {
    tabFilter.current = _data.id;
    getDataDash(dashData.base);
  };

  useEffect(() => {
    dataDashGetData();
    getAllUserAllerts();
  }, []); // Só chama uma vez ao montar o componente

  useEffect(() => {
    //ajusta o state dos modais
    setModalStates(
      AllUserAlerts != undefined ? AllUserAlerts?.map(() => true) : [true]
    );

    if (!dashInformations) return;

    let dataFilter = Object.keys(dashInformations).filter((item) =>
      /(mes|trimestre|semestre|ano)/.test(item)
    );

    const map_filter = dataFilter.map((item) => ({
      label: dashInformations[item].tipoPeriodo,
      value: item,
    }));

    setFilterList(map_filter);
    setDashBase(dashInformations);
  }, [dashInformations]);

  useEffect(() => {
    if (!dashBase) return;
    if (Object.keys(dashBase).length === 0) return;

    getDataDash(dashBase);
  }, [dashBase]);

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <MainFrameDash key={`filter-${tabFilter.current}`}>
      <Header>
        Bem vindo, <strong>{name}</strong>
      </Header>
      <NotificationArea style={{ display: notificationData ? "flex" : "none" }}>
        {notificationData}
      </NotificationArea>

      {AllUserAlerts?.map((element, index) => (
        <DashAlert
          key={index}
          showModal={modalStates[index]} // Passa o estado específico para o modal
          setShowModal={() => closeModal(index)} // Passa a função para fechar o modal
          alertInformation={element}
          markAlertAsRead={MarkAlertAsRead}
        />
      ))}

      <section className="area-section">
        <div className="filter">
          {filterList?.length > 0 ? (
            <div className="filter-title">
              <strong>Filtro</strong>:
            </div>
          ) : (
            ""
          )}
          {filterList.map((item, id) => {
            return (
              <>
                <Button
                  onClick={() => alterFilterTab({ ...item, id })}
                  type={~~tabFilter.current === id ? "" : "secondary"}
                  name={item.label}
                  width="143px"
                ></Button>
              </>
            );
          })}
        </div>

        <DynamicDash data={dashData?.data} key={`tab-${~~view}`} />
      </section>
    </MainFrameDash>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDashDataInformation: () =>
    dispatch(documentsAction.getDashboardInformationsRequest()),

  getAllUserAllerts: () => dispatch(doubtsAction.getAllGeneralAlertsRequest()),

  MarkAlertAsRead: (payload) =>
    dispatch(doubtsAction.MarkAsReadRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.documents.isLoading,
  dashInformations: state.documents.dashInformations,
  AllUserAlerts: state.doubts.AllUserAlerts,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardPlugMain);
