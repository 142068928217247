import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  candidatesVacancysDocsRequest: ["payload"],
  candidatesVacancysDocsSuccess: ["stages", "isLoading"],
  candidatesVacancysDocsFail: ["isLoading", "error"],

  candidateFilesRequest: ["payload"],
  candidateFilesSuccess: ["stages", "isLoading"],
  candidateFilesFail: ["isLoading", "error"],

  dowloadDocumentsFilesRequest: ["payload"],
  dowloadDocumentsFilesSuccess: ["stages", "isLoading"],
  dowloadDocumentsFilesFail: ["isLoading", "error"],

  candidateSetStatusFileRequest: ["payload"],
  candidateSetStatusFileSuccess: ["stages", "isLoading"],
  candidateSetStatusFileFail: ["isLoading", "error"],

  candidateSetStatusStageRequest: ["payload"],
  candidateSetStatusStageSuccess: ["stages", "isLoading"],
  candidateSetStatusStageFail: ["isLoading", "error"],

  candidateIncludeNewDocRequest: ["payload"],
  candidateIncludeNewDocSuccess: ["stages", "isLoading"],
  candidateIncludeNewDocFail: ["isLoading", "error"],

  candidateIncludeNewDocToSignRequest: ["payload"],
  candidateIncludeNewDocToSignSuccess: ["stages", "isLoading"],
  candidateIncludeNewDocToSignFail: ["isLoading", "error"],

  getDashboardInformationsRequest: ["payload"],
  getDashboardInformationsSuccess: ["stages", "isLoading"],
  getDashboardInformationsFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  candidates: {
    candidatos: [],
    candidatosPorVaga: [],
  },
  hasMore: true,
  page: 1,
  isLoading: false,
  dashInformations: {}
};

const candidatesVacancysDocsRequest = (state) => ({
  ...state,
  isLoading: true,
});
const candidatesVacancysDocsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const candidatesVacancysDocsFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const candidateFilesRequest = (state) => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const candidateFilesSuccess = (state, payload) => ({
  ...payload,
  isLoading: false,
});
const candidateFilesFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const dowloadDocumentsFilesRequest = (state) => ({ ...state, isLoading: true });
const dowloadDocumentsFilesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const dowloadDocumentsFilesFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const candidateSetStatusFileRequest = (state) => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const candidateSetStatusFileSuccess = (state, payload) => ({
  ...payload,
  isLoading: false,
});
const candidateSetStatusFileFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const candidateSetStatusStageRequest = (state) => ({
  ...INITIAL_STATE,
  isLoading: true,
});
const candidateSetStatusStageSuccess = (state, payload) => ({
  ...payload,
  isLoading: false,
});
const candidateSetStatusStageFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const candidateIncludeNewDocRequest = (state, payload) => ({
  ...payload,
  isLoading: true,
});
const candidateIncludeNewDocSuccess = (state, payload) => ({
  ...payload,
  isLoading: false,
});
const candidateIncludeNewDocFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const candidateIncludeNewDocToSignRequest = (state, payload) => ({
  ...payload,
  isLoading: true,
});
const candidateIncludeNewDocToSignSuccess = (state, payload) => ({
  ...payload,
  isLoading: false,
});
const candidateIncludeNewDocToSignFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


const getDashboardInformationsRequest = (state) => ({
  ...state,
  isLoading: true,
});

const getDashboardInformationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getDashboardInformationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});


export default createReducer(INITIAL_STATE, {
  [Types.CANDIDATES_VACANCYS_DOCS_REQUEST]: candidatesVacancysDocsRequest,
  [Types.CANDIDATES_VACANCYS_DOCS_SUCCESS]: candidatesVacancysDocsSuccess,
  [Types.CANDIDATES_VACANCYS_DOCS_FAIL]: candidatesVacancysDocsFail,

  [Types.CANDIDATE_FILES_REQUEST]: candidateFilesRequest,
  [Types.CANDIDATE_FILES_SUCCESS]: candidateFilesSuccess,
  [Types.CANDIDATE_FILES_FAIL]: candidateFilesFail,

  [Types.DOWLOAD_DOCUMENTS_FILES_REQUEST]: dowloadDocumentsFilesRequest,
  [Types.DOWLOAD_DOCUMENTS_FILES_SUCCESS]: dowloadDocumentsFilesSuccess,
  [Types.DOWLOAD_DOCUMENTS_FILES_FAIL]: dowloadDocumentsFilesFail,

  [Types.CANDIDATE_SET_STATUS_FILE_REQUEST]: candidateSetStatusFileRequest,
  [Types.CANDIDATE_SET_STATUS_FILE_SUCCESS]: candidateSetStatusFileSuccess,
  [Types.CANDIDATE_SET_STATUS_FILE_FAIL]: candidateSetStatusFileFail,

  [Types.CANDIDATE_SET_STATUS_STAGE_REQUEST]: candidateSetStatusStageRequest,
  [Types.CANDIDATE_SET_STATUS_STAGE_SUCCESS]: candidateSetStatusStageSuccess,
  [Types.CANDIDATE_SET_STATUS_STAGE_FAIL]: candidateSetStatusStageFail,

  [Types.CANDIDATE_INCLUDE_NEW_DOC_REQUEST]: candidateIncludeNewDocRequest,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_SUCCESS]: candidateIncludeNewDocSuccess,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_FAIL]: candidateIncludeNewDocFail,

  [Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_REQUEST]: candidateIncludeNewDocToSignRequest,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_SUCCESS]: candidateIncludeNewDocToSignSuccess,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_FAIL]: candidateIncludeNewDocToSignFail,

  [Types.GET_DASHBOARD_INFORMATIONS_REQUEST]: getDashboardInformationsRequest,
  [Types.GET_DASHBOARD_INFORMATIONS_SUCCESS]: getDashboardInformationsSuccess,
  [Types.GET_DASHBOARD_INFORMATIONS_FAIL]: getDashboardInformationsFail,
});
