import React, { createElement, useEffect, useState, useRef } from "react";

import IconCard from "./DashCards/IconCard";
import ListCard from "./DashCards/ListCard";
import ChartCard from "./DashCards/ChartCard";
import BarProgressCard from "./DashCards/BarProgressCard";
import DashTextLabel from "./DashText/DashLabel";
import DashTextTitle from "./DashText/DashTitle";
import DashUIDivisor from "./DashUI/DashUIDivisor";

import "./dynamicdash.scss";

export const DynamicDash = (props) => {
  const dictionaryComponents = {
    iconcard: IconCard,
    listcard: ListCard,
    chartcard: ChartCard,
    barprogresscard: BarProgressCard,
    dashlabel: DashTextLabel,
    dashtitle: DashTextTitle,
    dashuidivisor: DashUIDivisor,
  };

  const [time, setTime] = useState(0);
  const [timeFormat, setTimeFormat] = useState("");
  const [ref, setRef] = useState(0);
  const intervalUpdate = useRef(null);
  
  const createDashItem = (_data) => {
    if (dictionaryComponents[_data.type]) {
      const temp = createElement(dictionaryComponents[_data.type], {data: _data});
      return (
        <div className={`${Object.values(_data.style).join(" ")}`}>{temp}</div>
      );
    }
    return "";
  };

  useEffect(() => {
    if (time && time >= 0 && !isNaN(props.time)) {
      if (time >= 0) {
        const temp = new Date();
        temp.setHours(0);
        temp.setMinutes(0);
        temp.setSeconds(time * 60);
        setTimeFormat(temp.toLocaleTimeString());

        intervalUpdate.current = setTimeout(() => {
          setTime(time - 1);
          clearTimeout(intervalUpdate.current);
        }, 1000);
      } else {
        setTimeout(() => props?.onUpdateData(), 0);
        setTime(props?.time);
        setRef(new Date().getTime());
      }
    }
  }, [time]);

  return (
    <div key={ref} className="dynamic-dash">
      <div className="ui-grid">
        {props?.data ? (
          <>{props?.data.map((item) => createDashItem(item))}</>
        ) : (
          <div className="loading"></div>
        )}
      </div>
    </div>
  );
};
