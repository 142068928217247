
import React from "react";
import { Modal, } from "react-bootstrap";
import { ButtonDefault } from "components/shared/CustomInputs";
import { FormattedMessage } from "react-intl";
import "./DashAlert.scss";

export default function DashAlert({ showModal, setShowModal, alertInformation, markAlertAsRead }) {

  return (
    <Modal centered={false} size="md" show={showModal} onHide={setShowModal} backdropClassName="custom-modal-backdrop">
      <Modal.Header closeButton>
        <Modal.Title>{alertInformation?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{alertInformation?.text}</Modal.Body>
      <Modal.Footer>
        <ButtonDefault
          variant="secondary"
          onClick={()=>{
            setShowModal(false);
            markAlertAsRead(alertInformation.id);
          }}
          name={
            <FormattedMessage id="sharedItems.myProfile.ModalEmailChange.continue" />
          }
        />
      </Modal.Footer>
    </Modal>
  );
}